.wrapper{
    position: relative;
    padding-top: 80px;
    padding-top: var(--default-navbar-height);
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    /*background-size: 200% 200%;*/
}

.introContent{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 32px;
    margin: 10px;
    z-index: 1;
}
.introUpper{
    margin-top: 90px;
    z-index: 1;

}

.buttonsContainer{
    display: flex;
    justify-content: center;
    margin: 40px 0;
}

.buttonsContainer button{
    margin: 0 10px;
}
.introTitle{
    font-size: 32px;
    color: var(--color-white);
    text-align: center;
    line-height: 72px;
    margin: 0;
    margin-bottom: 15px;
}

.introSubtitle{
    color: var(--color-white);
    font-size: 18px;
    text-align: center;
}
.introText{
    color: var(--color-white);
    font-size: 16px;
    text-align: center;

}
.bgClip{
    position: absolute;
    bottom: 0;
    width: 100%;
}
.bgClip path{
    /*fill: var(--color-lightgray);*/
}

@media (min-width: 1280px){
    .bgClip{
        display: none;
    }
    .wrapper{
        align-items: center;
    }
    .introTitle{
        font-size: 42px;
        line-height: 72px;
    }
    .introSubtitle{
        font-size: 28px;
    }
    .introText{
        font-size: 22px;
        width: 800px;
        max-width: 100%;
    }
}
@media (max-width: 768px) {
    .wrapper{

    }

    .introUpper{
        margin-top: 0;
    }

    .introTitle{
        font-size: 28px;
        line-height: 42px;
    }
    .introLargeSmaller{
        font-size: 24px;
        line-height: 42px;
    }

    .buttonsContainer{
        flex-wrap: wrap;
        margin: 10px 0;
    }

    .buttonsContainer button{
        margin-bottom: 20px;
    }
}

@media (max-width: 320px) {
    .introTitle{
        font-size: 22px;
        line-height: 28px;
    }
    .introSubtitle{
        line-height: 20px;
        font-size: 16px;
    }
    .introText{
        line-height: 20px;
        font-size: 14px;
    }
}
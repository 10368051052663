.container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(8px);
    transition: .5s opacity ease;
}

.container.hidden{
    opacity: 0;
    pointer-events: none;
}

.container.hidden .window{
    transform: translateY(1200px);
}

.window{
    width: 480px;
    max-width: 100%;
    background: white;
    border-radius: 5px;
    transition: .5s transform ease;
}

.topPanel{
    display: flex;
    justify-content: flex-end;
    padding-left: 40px;
}

.closeButtonWrapper{
    border-radius: 0 5px 0 5px;
    transition: .2s all ease-in;

}

.closeButtonWrapper button{
    padding: 20px;
    background: transparent;
    border-radius: 0 5px 0 5px;
    border: none;
    cursor: pointer;
}

.closeButtonWrapper button:focus{
    box-shadow: none;
    opacity: .8;
}

.closeButtonWrapper svg{
    width: 15px;
    height: 15px;
    transition: .3s all ease-in;
}

.closeButtonWrapper svg path{
    fill: var(--color-lightblue);
    stroke: var(--color-lightblue);
    transition: .3s all ease-in;
}

.closeButtonWrapper:hover svg path{
    fill: var( --color-purple);
    stroke: var( --color-purple);
}

.modalTitle{
    margin: 0;
    margin-top: 40px;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    color: var( --color-purple);
    width: 100%;
}

.titleDecoration{
    width: 13px;
    height: 90px;
    margin-right: 40px;
}

.content{
    padding: 0 40px 30px 40px;
}


@media (max-width: 768px) {
    .container{
        padding: 0;
        align-items: flex-end;
    }

    .window{
        padding-bottom: 20px;
        border-radius: 20px 20px 0 0;
    }

    .topPanel{
        padding-left: 0;
        position: relative;
    }

    .modalTitle{
        font-size: 22px;
        margin-top: 28px;
        position: absolute;
        left: 20px;
        right: 20px;
        width: auto;
        text-align: center;
        padding-bottom: 25px;
    }

    .content{
        padding: 40px 20px 0 20px;
    }
}
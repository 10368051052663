.wrapper{
    margin: 14px 0;
    display: flex;
    align-items: center;
    position: relative;
}

.wrapper.empty.untouched svg path, .wrapper.empty.touched.valid svg path{
    stroke: var( --color-purple);
}

.valid svg path{
    stroke: var(--color-green);
}

.invalid svg path{
    stroke: darkred;
}

.wrapper svg{
    position: absolute;
    margin-right: -22px;
}

.wrapper.empty .countryCode{
    opacity: .5;
}
.wrapper.touched svg{

}
.wrapper.empty.untouched svg, .wrapper.empty.valid svg{
    opacity: .5;
}

.countryCode{
    color: var( --color-purple);
    font-size: 16px;
    position: absolute;
    left: 38px;
    top: 10px;
}

.wrapper.typePhone input{
    padding-left: 60px;
}

.wrapper input{
    background: transparent;
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: 16px;
    color: var( --color-purple);
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    padding: 10px;
    padding-left: 36px;
    border-bottom: 1px solid lightgray;
    border-radius: 0;
}

.wrapper input::placeholder{
    color: var( --color-purple);
    opacity: .5;
}

.wrapper input:focus{
    box-shadow: none;
    border-color: var( --color-orange);
    border-radius: 0;
}

.placeholder{
    display: none;
    color: var( --color-purple);
    font-size: 16px;
    opacity: .5;
    position: absolute;
    left: 36px;
    pointer-events: none;
}

.typePhone .placeholder{
    left: 60px;
}

.empty .placeholder{
    display: block;
}

@media (max-width: 768px) {
    .placeholder{
        left: 40px;
        font-size: 16px;
    }

    .countryCode{
        top: unset;
    }
}

@media (max-width: 380px) {
    .placeholder{
        font-size: 16px;
    }
}
@media (max-width: 320px) {
    .placeholder{
        font-size: 12px;
    }
}
.footerWrapper{
    padding: 0 calc((100% - 1200px) / 2);
    padding-top: 120px;
    padding-bottom: 60px;
    background: white;
}

.footerContent{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.columnGroup{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
}

.column{
    margin: 0 40px;
    flex-grow: 1;
}

.column:first-child{
    margin-left: 0;
}

.column:last-child{
    margin-right: 0;
}

.columnTitle{
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 20px 0;
    color: var( --color-purple);
}

.footerLink{
    display: block;
    text-decoration: none;
    cursor: pointer;
    padding: 10px 0;
    font-size: 16px;
    color: var(--color-lightblue);
    transition: .3s all ease;
}

.footerLink:hover{
    color: var( --color-orange);
}

.label{
    color: var(--color-lightblue);
    font-weight: 400;
}

.logo{
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logoImageWrapper{
    width: 48px;
    transition: .2s width linear;
}

.logoImageWrapper svg path{

}

.logoText{
    color: var( --color-purple);
    font-size: 24px;
    margin-left: 10px;
    font-weight: bold;
    transition: .2s font-size linear;
}

.motoText{
    font-size: 18px;
    line-height: 28px;
    color: var(--color-lightblue);
    max-width: 220px;
    margin: 20px 0;
    padding-bottom: 30px;
}

.imageWrapper{
    width: 280px;
    margin-bottom: -60px;
    margin-left: -120px;
    display: flex;
}

.imageWrapper svg{
    width: 100%;
}



@media(max-width: 1280px){
    .footerWrapper{
        padding-left: 40px;
        padding-right: 40px;
    }
    .columnGroup{
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .formWrapper{
        max-width: 80%;
        margin-left: 0;
    }
}
@media(max-width: 768px){
    .footerWrapper{
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 0;
    }

    .footerContent{
        flex-direction: column-reverse;
        align-items: center;
    }

    .formWrapper{
        max-width: 100%;
    }

    .columnGroup{
        width: 100%;
        flex-direction: column-reverse;
        align-items: stretch;
    }
    .column{
        width: 100%;
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .column:first-child{
        margin-bottom: 0;
    }
    .column > *{
        text-align: center;
    }
    .motoText{
        max-width: 100%;
        text-align: center;
    }

    .imageWrapper{
        width: 100%;
        margin: 0;
    }

    .footerContent button{
        width: 100%;
    }
}
.featureItem{
    padding: 50px;
    color: var( --color-purple);
    border-radius: 15px;
    width: 360px;
    height: 540px;
    margin: 10px;
    box-sizing: border-box;
    background: white;
    border: 1px solid #efefef;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}
.iconWrapper{
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    color: white;

}

.step{
    z-index: 2;
    font-weight: 300;
    font-size: 12px;
}
.step > span{
    font-size: 30px;
    font-weight: 400;
}

.iconBg{
    position: absolute;
    border-radius: 100%;
    top: -10px;
    bottom: -10px;
    right: -10px;
    left: -10px;
    animation: gradient 10s linear infinite;
}

.iconWrapper svg{
    position: relative;
    width: 64px;
}

.iconWrapper svg path, .iconWrapper svg circle{
    fill: var(--color-white);
}

 .iconBg{
    background-image: var(--main-gradient);
}


.title{
    font-size: 20px;
    margin: 0 0 18px 0;
}

.subtitle{
    font-size: 16px;
    line-height: 28px;

    color: var(--color-lightblue);
}

.featureItem a{
    color: var(--color-purple);
    font-weight: bold;
    text-decoration: none;
    border-bottom: 1px dashed var(--color-purple);
}

@media (max-width: 1580px) {
    .featureItem{
        flex-grow: 1;
        width: 45%;
    }
}

@media (max-width: 768px) {
    .featureItem{
        margin: 20px;
        padding: 30px 20px;
        height: auto;
    }
}

@media (max-width: 320px) {
    .title{
        font-size: 16px;
    }
    .subtitle{
        font-size: 14px;
        line-height: 16px;
    }
}

@keyframes gradient {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

.priceOffer{

    max-width: 100%;
    box-sizing: border-box;;
    transition: .5s all ease;
    border: 1px solid transparent;
    background: white;
    margin: 30px 0;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.02);
}

.mainContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.iconContainer svg{
    width: 100%;
}

.iconContainer svg path{
    fill: var( --color-orange);
}

.name{
    margin-top: 40px;
    color: var( --color-purple);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
}

.priceContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.price{
    font-size: 24px;
    line-height: 32px;
    color: var( --color-orange);
    font-weight: bold;
}

.duration{
    font-size: 16px;
    margin-left: 8px;
    color: var(--color-lightblue);
}

.separator{

}

.featuresContainer{
    margin-top: 30px;
    width: 100%;
}

.feature{

    margin: 20px 0;
    font-size: 16px;
    color: var(--color-lightblue);
}

.feature:first-child{
    margin-top: 0;
}

.priceOffer button{
    margin-top: 40px;
}
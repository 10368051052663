.wrapper{
    display: flex;
    align-items: center;
}

.wrapper svg{
    width: 22px;
    height: 22px;
    margin-right: 10px;
    flex-shrink: 0;
}

.succeeded.wrapper svg path{
    stroke: green;
}

.failed.wrapper svg path{
    stroke: darkred;
}

.text{
    font-size: 12px;
    color: var(--color-dart);
}
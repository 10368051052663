.hidden{
    opacity: 0;
    pointer-events: none;
}

.wrapper{
    background: radial-gradient(#fff, transparent);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
}

.wrapper svg{
    width: 60px;
    height: auto;
}

.text{
    font-size: 12px;
    color: var(--color-purple);
    text-shadow: 0 0 10px white;
    margin-top: 10px;
    font-weight: 300;
}
/*svg.loaderImage path{*/
    /*fill: red;*/
/*}*/

.button{
    border: 1px solid transparent;
    border-radius: 40px;
    padding: 15px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: .2s transform ease!important;
    font-weight: 600;
    font-family: var(--default-font-family);
    border: none;
}

.white{
    background: transparent;
    color: var(--color-white);
    border-color: var(--color-white);
}

.white.filled{
    background: var(--color-white);
    color: var( --color-purple);
    border-color: transparent;
}

.purple{
    background: transparent;
    color: var( --color-purple);
    border-color: var( --color-purple);
}

.purple.filled{
    background: linear-gradient(200deg, #e3a384, #8571a4);
    color: var(--color-white);
    border-color: transparent;
}


.button:active{
    transform: scale(.95);
}


@media (max-width: 320px) {
    .button{
        font-size: 14px;
        text-align: center;
        justify-content: center;
    }
}
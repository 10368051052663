.socialsContainer{
    display: flex;
    gap: 15px;

}

.socialLink{
    border-radius: 100%;
    box-sizing: border-box;
    display: flex;
}

.socialLink:last-child{
    margin-right: 0;
}

.socialLink svg{
    width: 36px;
    height: 36px;
}

.socialLink svg path{
    fill: var(--color-lightblue);
    transition: .3s all ease;
}

.socialLink:hover svg path{
    fill: var( --color-orange);
}
.title{
    margin: 0;
    font-size: 42px;
    line-height: 44px;
    font-weight: bold;
    color: var( --color-purple);
}

@media (max-width: 768px) {
    .title{
        font-size: 28px;
    }
}

@media (max-width: 320px) {
    .title{
        font-size: 20px;
        line-height: 28px;
    }
}
.text{
    margin: 0;
    color: var(--color-lightblue);
    font-size: 20px;
    line-height: 28px;
}

@media (max-width: 768px) {
    .text{
        font-size: 18px;
        line-height: 24px;
    }
}
@media (max-width: 320px) {
    .text{
        font-size: 14px;
        line-height: 22px;
    }
}
@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-Italic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-BoldItalic.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

body {
    margin: 0;
    font-family: var(--default-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-black);
}

:root{
    --color-black: #252525;
    --color-white: #f4f4f4;
     --color-orange: #e3a384;
     --color-purple: #8571a4;
    --color-lightblue: #86779a;
    --color-lightgray: #efefef;
    --color-gray: #ebebeb;
    --color-darkishgray: #f6f7fa;
    --color-green: #00b916;
    --default-navbar-height: 110px;
    --default-font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    --main-gradient: linear-gradient(#e3a384, #8571a4);
}

*:focus{
    outline: none;
}

input,
textarea,
button,
select,
div,
a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.grecaptcha-badge { visibility: hidden; }
.pricingBlock{

}

.desktopFlexBlock{
    display: flex;
    margin: auto;
}

.captions{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}

.pricingBlock p{
    margin: 20px;
}

.offersContainer{
    display: flex;
    justify-content: center;
}

.offersContainer > div:nth-child(1) svg path{
    fill: silver;
}

.offersContainer > div:nth-child(2) svg path{
    fill: gold;
}

.offersContainer > div:nth-child(3) svg path{
    fill: #e92460;
}
.certificateButton{

}
.certificate{
    width: 320px;
    height: auto;
    border-radius: 25px;
}
.priceAndCaptions{
    margin-left: 30px;
    text-align: left;
    flex-grow: 1;
}
.priceAndCaptions p{
    margin: 0;
}
.pricingBlock h2{
    margin-bottom: 30px;
}
@media(min-width: 769px) {
    .hideDesktop{
        display: none;
    }
}

@media(max-width: 768px) {

    .hideMobile{
        display: none;
    }
    .priceAndCaptions{
        margin-left: 0;
    }
    .pricingBlock p{
        margin: 0;
        margin-bottom: 20px;
        text-align: center;
    }
    .priceAndCaptions p{
        margin-top: 20px;
        text-align: center;
    }
    .pricingBlock h2{
        text-align: center;
    }
    .desktopFlexBlock{
        display: block;
    }
    .certificate{
        width: 100%;
    }
    .certificateButton{
        width: 100%;
    }
    .captions {
        margin-bottom: 30px;
    }

    .offersNegativeMargin {
        margin: 0 -20px;
    }

    .offersContainer {
        box-sizing: border-box;
        padding: 0 20px;
    }
}